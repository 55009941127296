<template>
    <!--
    Страница согласия с cookies
    -->
    <el-container class="wrapper_policy">
        <el-card class="policy-card">
            <el-row type="flex" align="middle" justify="space-between" slot="header" class="clearfix">
                <span style="font-size: 20px; font-weight: bold; padding-right: 15px">Согласие с политикой по обработке персональных данных</span>
                <el-button style="float: right; padding: 3px 0; font-size: 18px" type="text" @click="logout">
                    <router-link :to="{}" class="logout_button">
                        Выход
                    </router-link>
                </el-button>
            </el-row>
            <el-row>
                Продолжая работу, вы соглашаетесь с политикой по обработке персональных данных.
                <router-link :to="{name: 'PrivacyPolicy'}" class="logout_button">
                    Узнать больше
                </router-link>
            </el-row>
            <el-row type="flex" justify="end">
                <el-button type="success" style="margin-top: 30px;" @click="acceptTerms">
                    Принять
                </el-button>
            </el-row>


        </el-card>
    </el-container>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/router";

export default {
    name: "PrivacyAgreement",
    methods: {
        ...mapActions("login", ["clear_code"]),
        ...mapActions("user", ["save_user_policy"]),
        logout() {
            this.clear_code().then(() => router.replace({name: "code"}))
        },
        acceptTerms() {
            this.save_user_policy()
                .then(() => this.$router.push({name: 'profile'}))
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.wrapper_policy {
    padding-top: 40px;

    .policy-card {
        margin: 0 auto;
        width: 90%;
    }

    .logout_button {
        text-decoration: none;
        color: $dom-light-blue;
    }

    .logout_button:hover {
        text-decoration: underline;
    }

    .el-header {
        padding: 0;
    }
}
</style>
